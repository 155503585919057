<template>
  <div class="order">
    <div class="orderCon">
      <p>
        <span class="rest_total">预约金额</span>
        <span class="expect">预计收益（元）:<b>{{ interestTotal }}</b><em @click="tsFun()">!</em></span>
      </p>
      <el-form label-width="100px" class="demo-ruleForm">
        <b>￥</b>
        <el-input v-model="reserveAmount" type="number" v-on:input="moneyChange" placeholder="请输入预约金额"></el-input>
        <span>万元</span>
      </el-form>
      <p class="rules">
        {{ accountData.productMinimumSubscribe | formatMoney }}起投，{{ accountData.doublyAmount | formatMoney }}递增
      </p>

    </div>

    <div class="button" @click="submitFun()">确定预约</div>

  </div>
</template>
<script>
import { selectProductDetail,reserveMatch} from "../services/proApi";
import $ from 'jquery'
export default {
  data() {
    return {
      reserveAmount: '',
      accountData: {},
      interestTotal: '--',
      isLoading: false,
      dialogShow: false,
      dialogMsg: '',
      btnName: '',
      rate: ''
    };
  },
  created: function() {
    //初始化数据
    var _this = this;
    var productId = _this.$route.query.productId;
    var signFalg = _this.$route.query.signFalg;
    _this.selectProductDetail()

  },
  methods: {
     //输入金额校验
    moneyChange() {
      var obj = this;
      var reserveAmount = (obj.reserveAmount) * 10000;//输入金额
      var productMinimumSubscribe = obj.accountData.productMinimumSubscribe;//起投金额
      //var rate=(obj.rate)/100;//取最低利率
      var yearRate = obj.accountData.yearRate;//产品基准
      var productPeriod = obj.accountData.productPeriod;//产品实际天数
      var profitRate = obj.accountData.profitRate * 100;//基础利率
      var floatRate = 0;//浮动利率
      var stairRate = 0;//阶梯
      if (reserveAmount < productMinimumSubscribe) {
        obj.$toast('预约金额不得小于起投金额');
        obj.interestTotal = "--";
        return false;
      } else if (obj.reserveAmount.length > 12) {
        obj.$toast('超过最大限制');
        return false;
      } else {
        //计算利率
        if (obj.accountData.floatRate != "" || obj.accountData.floatRate != undefined) {
          floatRate = obj.accountData.floatRate * 100;
        }
        var extedData = obj.accountData.extedData;
        var interestTotal, rate;
        console.log(extedData.length+"+++")
        if (extedData.length > 0) {
          let minMoney2 = extedData[0].minMoney * 10000;
          for (var i = 0; i < extedData.length; i++) {
            var minMoney = extedData[i].minMoney * 10000;
            var maxMoney = extedData[i].maxMoney * 10000;
            var stairRate = extedData[i].stairRate * 100;
            if (minMoney2 > minMoney) {
              minMoney2 = minMoney;
            }
            //判断输入金额是否小于阶梯里面最小金额
            if (stairRate == obj.accountData.minStairRate || reserveAmount < minMoney2) {
              rate = (profitRate + floatRate) / 10000;
            } else {
              if ((reserveAmount >= minMoney) && (reserveAmount < maxMoney)) {
                //console.log(stairRate+profitRate+floatRate)
                rate = (stairRate + profitRate + floatRate) / 10000;
              }
            }

          }
        } else {
          rate = (profitRate + floatRate) / 10000;
        }
        //console.log(stairRate+"基础利率："+profitRate+"浮动利率："+floatRate)
        //输入金额*利率*产品实际天数／产品基准（360）
        //输入金额*profitRate*productPeriod/yearRate
        interestTotal = reserveAmount * rate * productPeriod / yearRate;
        //console.log(reserveAmount+"="+rate+"=="+productPeriod+"--="+yearRate)
        obj.interestTotal = interestTotal.toFixed(2);
      }
    },
     //确定预约
   async submitFun() {
      var obj = this;
        var reserveAmount = obj.reserveAmount * 10000;
        if (reserveAmount == "" || isNaN(reserveAmount)) {
          obj.$toast('请输入预约金额');
          return false;
        }
        if ((reserveAmount * 10000) % 10000 != 0 || reserveAmount < 5) {
          obj.$toast('请输入正确的预约金额');
          return false;
        }
        var productId = obj.$route.query.productId;
        var signFalg = obj.$route.query.signFalg;
        var channelFlag = obj.$route.query.channelFlag;//渠道
        var scanId = sessionStorage.getItem("scanId");//扫码id
        var qrCode = sessionStorage.getItem("qrCode");//二维码标识

        obj.isLoading = true;
        var signFalg=obj.$route.query.signFalg;
        var productId=obj.$route.query.productId;
        const datas = await reserveMatch({reserveAmount: reserveAmount,productId: productId,signFalg: signFalg,scanId: scanId,qrCode: qrCode});
        if (datas.code === 1) {
            var productName = datas.data.parentProductName;
            var isBinded = datas.data.isBinded;
            var reserveId = datas.data.reserveId;
            var sign = datas.data.sign;
            var investUserName = datas.data.investUserName;
            var reserveAmount = datas.data.reserveAmount;
            obj.$router.push({
              path: "result", query: {
                productName: productName,
                investUserName: investUserName,
                reserveAmount: reserveAmount / 10000,
                channelFlag: channelFlag,
                isBinded,
                reserveId,
                sign,
                signType:datas.data.signType,
              }
            });
        } 
      

    },
   async selectProductDetail(){
        let _this=this;
        var signFalg=_this.$route.query.signFalg;
        var productId=_this.$route.query.productId;
        const datas = await selectProductDetail({productId: productId, signFalg: signFalg});
        if (datas.code === 1) {
            _this.accountData = datas.data;
            //获取最低的利率
            var profitRate = datas.data.profitRate;
            var stairRate = datas.data.stairRate;
            var rate = profitRate;

            if (profitRate * 100 > stairRate * 100 && stairRate != undefined) {
              rate = profitRate;
            } else {
              rate = profitRate;
            }
            _this.rate = rate;
        }
   },
   tsFun() {
      this.$toast('预计收益仅供参考，具体收益以实际为准');
    }

  },
  filters: {
    formatMoney: function(money) {
      money = money > 9999 ? money / 10000 + "万" : money;
      return money;
    },
    formateDate: function(now) {
      var now = parseInt(now);
      var d = new Date(now);
      return d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
    }
  }
};
</script>

<style lang="scss">
.order{
  .orderCon {
      background: #fff;
      padding: 2.5rem 2rem;
      margin-top: 1rem;
      p{display: flex;justify-content: space-between;padding-bottom: 1rem;font-size: 1rem;}
      .expect{
        text-align: right;
        b{color: #356BFF;font-weight:bold;}
        em{
          border: 1px solid #2D7AFB;color:#2D7AFB;line-height: 0.7rem;
          width: 0.7rem;
          height: 0.7rem;
          border-radius: 50%;
          display: inline-block;
          text-align: center;
          font-size: 0.6rem;
          margin-left: 0.5rem;
        }
      }
      .demo-ruleForm{
        border-bottom: 1px solid #dfdfdf;
        b{width: 7%;color: #333;font-size: 1.3rem;font-weight: normal;}
      }
      .el-input{
        display: inline-block;width: 80%;
        input{height:4rem;line-height:4rem;border:none;color:#356BFF;font-size:2rem;font-weight: 500;}
      }
      .rules{margin-top: 1rem;color: #999;padding: 0;font-size: 0.8rem;}
  }
  .btn {
      width: 80%;
      border-radius: 2.5rem;
      margin: 2rem auto;
      height: 2.6rem;
      line-height: 2.6rem;
      text-align: center;
      padding: 0;
      font-size:0.8rem;
  }
}
</style>
